import { EventBus } from '@/events/EventBus'

export const Bus = new EventBus()

export const Events = Object.freeze({
	CHANGE_CATEGORY: 'change-category',
	GET_OFFERS: 'get-offers',
	SHOW_CATALOG_OFFERS: 'show-catalog-offers',
	SHOW_CATALOG_SERVICES: 'show-catalog-services'
})
