<!-- eslint-disable vue/no-v-html -->
<template>
	<w-layout column fill-height>
		<w-flex v-if="loading" fill-height>
			<w-layout align-center fill-height justify-center>
				<v-progress-circular color="primary" indeterminate :size="50" />
			</w-layout>
		</w-flex>
		<w-flex v-else scroll-y>
			<EditCatalogOffer
				v-model="catalogOfferSelected"
				:action="action"
				:opened="dialogOpened"
				@addOffer="onCatalogOfferCreated($event)"
				@close="dialogOpened = false"
				@updateOffer="onUpdateOffer($event)"
			/>
			<w-layout column fill-height>
				<w-flex v-if="!isReadonly" shrink>
					<w-layout justify-end>
						<w-btn @click="createOffer()">{{ $t('offers.create_offer') }}</w-btn>
					</w-layout>
				</w-flex>
				<w-flex v-if="catalogOffers.length == 0" scroll-y>
					<w-layout align-center fill-height justify-center>
						<w-flex v-if="isSearched" v-t="'offers.no_offer_corresponding'" class="headline" text-xs-center />
						<w-flex v-else v-t="'offers.no_offer_record'" class="headline" text-xs-center />
					</w-layout>
				</w-flex>
				<w-flex v-else scroll-y>
					<v-expansion-panel>
						<v-expansion-panel-content v-for="(catalogOffer, index) in catalogOffers" :key="catalogOffer.id">
							<template v-slot:header>
								<w-layout
									align-center
									@click="catalogOfferClicked == index ? (catalogOfferClicked = null) : (catalogOfferClicked = index)"
									@mouseleave="catalogOfferHovered = null"
									@mouseover="catalogOfferHovered = index"
								>
									<w-flex shrink v-text="catalogOffer.name" />
									<w-flex shrink>
										<v-avatar v-for="category in catalogOffer.categories" :key="category.id" style="margin: -8px">
											<!-- Category color -->
											<v-tooltip top>
												<template v-slot:activator="{ on }">
													<v-icon :color="category.color" large v-on="on">brightness_1</v-icon>
													<span
														style="
															position: absolute;
															top: 50%;
															left: 50%;
															-moz-transform: translate(-50%, -50%);
															transform: translate(-50%, -50%);
															color: white;
															font-size: 0.8em;
															font-weight: bold;
														"
														v-on="on"
														v-text="category.abbreviation"
													/>
												</template>
												<span v-text="category.name" />
											</v-tooltip>
										</v-avatar>
									</w-flex>
									<v-spacer />

									<w-flex
										shrink
										subheading
										v-text="
											isNaN(catalogOffer.price)
												? $t('not-known')
												: catalogOffer.duration
												? `${$n(catalogOffer.price, 'currency')} / ${$t(`offers.durations.${catalogOffer.duration.name}`)}`
												: $n(catalogOffer.price, 'currency')
										"
									/>
									<w-flex v-if="!isReadonly" align-center shrink>
										<w-layout align-center fluid row>
											<div class="align-center" style="position:relative">
												<w-flex align-center shrink>
													<w-btn class="ma-0 pa-0" flat icon="" mini/>
													<w-btn class="ma-0 pa-0" flat icon="" mini/>
												</w-flex>
												<v-fade-transition>
													<w-flex
														v-if="catalogOfferHovered == index || catalogOfferHovered == index"
														align-center
														shrink
														style="left:0;position:absolute;top:0"
													>
														<w-btn class="ma-0 pa-0" flat icon="settings" mini @click.stop="updateCatalogOffer(catalogOffer)"/>
														<w-btn class="ma-0 pa-0" flat icon="delete" mini @click.stop="deleteCatalogOffer(catalogOffer.id)"/>
													</w-flex>
												</v-fade-transition>
											</div>
										</w-layout>
									</w-flex>
								</w-layout>
							</template>
							<v-card>
								<v-card-text>
									<w-layout column>
										<w-layout v-if="catalogOffer.description" column>
											<v-subheader v-t="'description'" />
											<v-divider :color="$vuetify.theme.primary" />
											<w-flex px-2 justify-space-around v-html="catalogOffer.description" />
										</w-layout>
										<w-layout v-if="catalogOffer.catalog_services" column>
											<v-subheader v-t="'offers.suggested_offers'" />
											<v-divider :color="$vuetify.theme.primary" />
											<v-data-table :headers="headers" hide-actions :items="catalogOffer.catalog_services">
												<template v-slot:items="{ item: catalogService }">
													<td v-text="catalogService.title" />
													<td v-text="isNaN(catalogService.price) || catalogService.price === null ? $t('not-known') : $n(catalogService.price, 'currency')" />
													<td>
														<v-edit-dialog
															:return-value.sync="catalogService.pivot.quantity"
															lazy
															@close="saveQuantity(catalogOffer.id, catalogService.id, newQuantity)"
															@open="openQuantitySelector(catalogService.pivot.quantity)"
														>
															{{ 'x' + catalogService.pivot.quantity }}
															<template v-slot:input>
																<v-text-field
																	v-model="newQuantity"
																	color="primary"
																	:label="$t('quantity')"
																	min="1"
																	:readonly="isReadonly"
																	style="width: 75px"
																	type="number"
																/>
															</template>
														</v-edit-dialog>
													</td>
													<td
														v-text="
															isNaN(catalogService.price) || catalogService.price === null
																? undefined
																: $n(catalogService.price * catalogService.pivot.quantity, 'currency')
														"
													/>
												</template>
											</v-data-table>
										</w-layout>
									</w-layout>
								</v-card-text>
							</v-card>
						</v-expansion-panel-content>
					</v-expansion-panel>
				</w-flex>
			</w-layout>
		</w-flex>
		<w-flex shrink>
			<v-layout row>
				<v-flex xs12 sm10 offset-sm1 md8 offset-md2 lg6 offset-lg3 xl4 offset-xl4>
					<w-pagination
						:item-name="$tc('offers.offers', 2).toLowerCase()"
						:page-number="pagination.page"
						:rows-per-page="pagination.rowsPerPage"
						:total-items="pagination.totalItems"
						@update:page-number="setPaginationPage($event)"
						@update:rows-per-page="setPaginationRowsPerPage($event)"
					/>
				</v-flex>
			</v-layout>
		</w-flex>
	</w-layout>
</template>

<script>
import OffersModuleGuard from '@/mixins/ModulesGuards/Offers/OffersModuleGuard'

export default {
	name: 'ListOfCatalogOffers',
	components: {
		EditCatalogOffer: () => ({
			component: import('@/components/Offers/EditCatalogOffer')
		})
	},
	mixins: [OffersModuleGuard],
	data: function () {
		return {
			action: 'create',
			cancelToken: null,
			catalogOfferSelected: {},
			catalogOfferClicked: null,
			catalogOfferHovered: null,
			catalogOffers: [],
			dialogOpened: false,
			headers: [
				{
					sortable: true,
					text: this.$t('offers.catalog_service'),
					value: 'title'
				},
				{
					sortable: false,
					text: this.$t('offers.unit_price')
				},
				{
					sortable: false,
					text: this.$t('quantity')
				},
				{
					sortable: false,
					text: this.$t('offers.total_price')
				}
			],
			loading: false,
			newQuantity: 1,
			isSearched: false,
			pagination: {
				page: 1,
				rowsPerPage: 15,
				totalItems: 0
			}
		}
	},
	computed: {
		isReadonly: function () {
			return this.service.getIsReadonly()
		}
	},
	mounted: function () {
		this.listCatalogOffers()
	},
	methods: {
		getModuleEventsActionsMapping: function () {
			return [{ event: this.events.GET_OFFERS, action: this.listCatalogOffers }]
		},
		createOffer: function () {
			this.action = 'create'
			this.dialogOpened = true
		},
		deleteCatalogOffer: function (catalogOfferId) {
			this.$dialog
				.warning({
					text: this.$t('actions.are_you_sure'),
					title: this.$t('offers.delete_offer'),
					actions: {
						false: this.$t('actions.no'),
						true: this.$t('actions.yes')
					}
				})
				.then(res => {
					if (res) {
						this.service.deleteCatalogOffer(this.accountingFirmId, catalogOfferId).then(() => {
							this.catalogOffers = this.catalogOffers.filter(item => item.id != catalogOfferId)
						})
					}
				})
		},
		listCatalogOffers: function (search = {}) {
			this.loading = true
			if (search.categories) {
				if (search.categories.length > 0) {
					this.isSearched = true
				} else {
					this.isSearched = false
				}
				search.categories = search.categories.join()
			} else if (search.text && search.text.length > 0) {
				this.isSearched = true
			} else {
				this.isSearched = false
			}
			const callAnswer = this.service.listCatalogOffers(this.accountingFirmId, this.pagination, search, this.cancelToken)
			this.cancelToken = callAnswer.cancelToken

			return callAnswer.promise
				.then(({ catalogOffers, paginationData }) => {
					this.catalogOffers = catalogOffers
					this.pagination.page = paginationData.currentPage
					this.pagination.rowsPerPage = paginationData.perPage
					this.pagination.totalItems = paginationData.total
				})
				.finally(() => {
					this.loading = false
				})
		},
		onCatalogOfferCreated: function (catalogOffer) {
			this.catalogOffers.push(catalogOffer)
		},
		onUpdateOffer: function (event) {
			const catalogOffer = this.catalogOffers.find(co => co.id === event.id)

			if (!catalogOffer) {
				return
			}

			catalogOffer.update(event)
		},
		openQuantitySelector: function (quantity) {
			this.newQuantity = quantity
		},
		saveQuantity: function (catalogOfferId, catalogServiceId, quantity) {
			if (!this.isReadonly) {
				this.service.updateCatalogServiceQuantity(this.accountingFirmId, catalogOfferId, catalogServiceId, quantity).then(() => {
					const catalogOffer = this.catalogOffers.find(co => co.id === catalogOfferId)

					if (!catalogOffer) {
						return
					}

					const catalogService = catalogOffer.catalog_services.find(cs => cs.id === catalogServiceId)

					if (!catalogService) {
						return
					}

					catalogService.pivot.quantity = quantity
				})
			}
		},
		setPaginationPage: function (pageNumber) {
			this.pagination.page = pageNumber
		},
		setPaginationRowsPerPage: function (rowsPerPage) {
			this.pagination.rowsPerPage = rowsPerPage
			this.listCatalogOffers()
		},
		updateCatalogOffer: function (catalogOffer) {
			this.action = 'update'
			this.catalogOfferSelected = catalogOffer
			this.dialogOpened = true
		}
	}
}
</script>
