import Model from '@/classes/Model'

import Duration from '@/classes/Duration'
import CatalogService from '@/classes/Offers/CatalogService'

export default class CatalogOffer extends Model {
	/**
	 * @param {object} catalogOffer
	 */
	// eslint-disable-next-line space-before-function-paren
	constructor(catalogOffer) {
		const catalogServices = catalogOffer.catalog_services
		delete catalogOffer.catalog_services

		const duration = catalogOffer.duration
		delete catalogOffer.duration

		super(catalogOffer)

		if (Array.isArray(catalogServices)) {
			this.catalog_services = catalogServices.map(cs => new CatalogService(cs))
		}

		if (duration) {
			this.duration = new Duration(duration)
		}
	}

	/**
	 * @return {Array<Category>}
	 */
	get categories () {
		if (!this.catalog_services) {
			return undefined
		}

		let result = []
		const uniqueCategoryIds = new Set()

		this.catalog_services.forEach(cs => {
			const category = cs.category
			if (uniqueCategoryIds.has(category.id)) {
				const categoryIndex = result.findIndex(c => c.id === category.id)
				result[categoryIndex].count++
			} else {
				uniqueCategoryIds.add(category.id)
				category.count = 1
				result.push(category)
			}
		})

		return result
	}

	/**
	 * @param {string} field
	 * @param {any} value
	 */
	_updateField (field, value) {
		if (field == 'catalog_services') {
			this.catalog_services = value.map(cs => {
				if (CatalogService.prototype.isPrototypeOf(cs)) {
					return cs
				} else {
					return new CatalogService(cs)
				}
			})
		} else {
			this[field] = value
		}
	}
}
