// ============================================================================
// OffersService
// -------------
// Offers module related services
// ============================================================================

// -------
// Imports
// -------
import { store } from '@/store'

import API from '@/apis/OffersApi'

import AppService from '@/services/AppService'
import { Bus as AppEventBus, Events as AppEvents } from '@/events/AppEvents'

import CatalogService from '@/classes/Offers/CatalogService'
import CatalogOffer from '@/classes/Offers/CatalogOffer'
import Duration from '@/classes/Duration'

// ---------
// Internals
// ---------
const Private = {
	addCategory: function (category) {
		return Private.dispatch('addCategory', category)
	},
	listCategories: function (accountingFirmId) {
		return API.listCategories(accountingFirmId)
			.then(res => res.data.data)
			.then(categories => {
				return Private.setCategories(categories)
			})
			.then(() => {
				return store.state.offers.categories
			})
	},
	removeCategory: function (categoryId) {
		return Private.dispatch('removeCategory', categoryId)
	},
	replaceCategory: function (categoryData) {
		return Private.dispatch('replaceCategory', categoryData)
	},
	setCategories: function (categories) {
		return Private.dispatch('setCategories', categories)
	},
	setCategory: function (category) {
		return Private.dispatch('setCategory', category)
	},
	getCancelToken: function (key) {
		return store.state.offers.cancelTokens[key]
	},
	setCancelToken: function (data) {
		return Private.dispatch('setCancelToken', data)
	},
	dispatch: function (action, data) {
		return store.dispatch(`offers/${action}`, data)
	},
	selectDefaultCategory: function () {
		Private.selectCategory(store.state.offers?.categories?.[0]?.id)
	},
	selectCategory: function (categoryId) {
		AppService.goTo({
			name: 'offers-category',
			params: {
				categoryId: categoryId?.toString?.(16)
			}
		})
	}
}

// -------
// Exports
// -------
export default {
	goTo: AppService.goTo,
	onAccountingFirmChanged: function (newAccountingFirmId) {
		return Private.listCategories(newAccountingFirmId)
	},
	listDurations: function () {
		return API.listDurations()
			.then(res => res.data.data)
			.then(durations => durations.map(d => new Duration(d)))
	},
	createCatalogOffer: function (accountingFirmId, data) {
		return API.createCatalogOffer(accountingFirmId, data)
			.then(res => res.data.data)
			.then(co => new CatalogOffer(co))
	},
	deleteCatalogOffer: function (accountingFirmId, catalogOfferId) {
		return API.deleteCatalogOffer({ accounting_firm_id: accountingFirmId, catalog_offer_id: catalogOfferId }).promise
	},
	listCatalogOffers: function (accountingFirmId, paginationData = {}, search = {}, cancelToken = null) {
		const data = {
			accounting_firm_id: accountingFirmId,
			categories: search.categories,
			page: paginationData.page,
			po: paginationData.rowsPerPage,
			text: search.text
		}
		const callAnswer = API.listCatalogOffers(data, cancelToken)
		return {
			...callAnswer,
			promise: callAnswer.promise
				.then(res => res.data)
				.then(({ data: foundData, pagination: foundPaginationData }) => {
					return {
						catalogOffers: foundData.map(co => new CatalogOffer(co)),
						paginationData: foundPaginationData
					}
				})
		}
	},
	getServicesForOffers: function (accountingFirmId) {
		return API.getServices(accountingFirmId).then(res => res.data.data)
	},
	updateCatalogOffer: function (accountingFirmId, offerId, data) {
		return API.updateCatalogOffer(accountingFirmId, offerId, data).then(res => res.data.data)
	},
	updateCatalogServiceQuantity: function (accountingFirmId, catalogOfferId, catalogServiceId, quantity) {
		return API.updateCatalogServiceQuantity(accountingFirmId, catalogOfferId, catalogServiceId, { quantity: quantity }).then(res => res.data.data)
	},
	createCategory: function (accountingFirmId, categoryData) {
		return API.createCategory(accountingFirmId, categoryData)
			.then(res => res.data.data)
			.then(category => {
				return Private.addCategory(category).then(() => {
					Private.selectCategory(category.id)
				})
			})
	},
	deleteCategory: function (accountingFirmId, categoryId) {
		return API.deleteCategory(accountingFirmId, categoryId)
			.then(() => Private.removeCategory(categoryId))
			.then(() => {
				const selectedCategory = store.state.offers.category
				if (selectedCategory?.id === categoryId) {
					Private.selectDefaultCategory()
				}
			})
	},
	onCategoryIdUpdated: function (newCategoryId) {
		const category = store.state.offers.categories.find(c => c.id === newCategoryId)
		return Private.setCategory(category)
	},
	selectCategory: Private.selectCategory,
	selectDefaultCategory: Private.selectDefaultCategory,
	updateCategory: function (accountingFirmId, categoryId, categoryData) {
		return API.updateCategory(accountingFirmId, categoryId, categoryData)
			.then(res => res.data.data)
			.then(category => {
				return Private.replaceCategory(category).then(() => Private.selectCategory(category.id))
			})
	},
	getAbbreviations: function () {
		return store.state.offers.categories.map(c => c.abbreviation)
	},
	createCatalogService: function (accountingFirmId, categoryId, catalogService) {
		return API.createCatalogService(accountingFirmId, categoryId, catalogService.forApi())
			.catch(err => {
				AppEventBus.emit(AppEvents.SNACKBAR_ERROR, err)
				return Promise.reject(err)
			})
			.then(res => res.data.data)
			.then(createdCatalogService => new CatalogService(createdCatalogService))
	},
	listCatalogServices: function (accountingFirmId) {
		return API.listCatalogServices(accountingFirmId)
			.then(res => res.data.data)
			.then(catalogServices => catalogServices.map(cs => new CatalogService(cs)))
	},
	listCatalogServicesOfCategory: function (accountingFirmId, categoryId) {
		const callAnswer = API.listCatalogServicesOfCategory(accountingFirmId, categoryId, Private.getCancelToken('listCatalogServicesOfCategory'))
		Private.setCancelToken({ cancelToken: callAnswer.cancelToken, item: 'listCatalogServicesOfCategory' })
		return callAnswer.promise
			.then(res => res.data)
			.then(data => {
				return {
					catalogServices: data.data.map(cs => new CatalogService(cs))
				}
			})
	},
	selectCatalogService: function (categoryId, catalogServiceId) {
		AppService.goTo({
			name: 'offers-category-catalog-service',
			params: {
				catalogServiceId: catalogServiceId.toString(16),
				categoryId: categoryId.toString(16)
			}
		})
	},
	updateCatalogService: function (accountingFirmId, categoryId, catalogService) {
		return API.updateCatalogService(accountingFirmId, categoryId, catalogService.id, catalogService.forApi())
			.then(res => res.data.data)
			.then(updatedCatalogService => new CatalogService(updatedCatalogService))
	},
	deleteCatalogService: function (accountingFirmId, catalogServiceId) {
		return API.deleteCatalogService(accountingFirmId, catalogServiceId)
	},
	listFrequenciesOptions: function () {
		return API.listFrequenciesOptions().then(res => res.data.data)
	},
	listDeliverables: function () {
		return API.listDeliverables().then(res => res.data.data)
	},
	listTaskTypes: function () {
		return API.listTaskTypes({ for_task_models: true }).promise.then(res => res.data.data)
	},
	getIsReadonly: function () {
		return !store.state.auth?.isAccountant ?? false
	}
}
