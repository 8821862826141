import { Config } from '@/services/ConfigService'
import { Configurator } from '@/plugins/axios/Configurator'

const axios = Configurator.defaultConfiguration(Config.VUE_APP_ROOT_API + '/api/')

/**
 * @api POST /api/accounting-firms/{accountingFirmId}/catalog-offers
 *
 * @param {number} accountingFirmId
 * @param {object} data
 * @return {Promise<object>}
 */
const createCatalogOffer = (accountingFirmId, data) => {
	const url = `accounting-firms/${accountingFirmId}/catalog-offers`
	return axios.post(url, data)
}

/**
 * @api DELETE /api/accounting-firms/{accountingFirmId}/catalog-offers/{catalogOfferId}
 *
 * @param {object} params
 * @param {number} params.accounting_firm_id
 * @param {number} params.catalog_offer_id
 *
 * @return {{promise: Promise<object>}}
 */
const deleteCatalogOffer = params => {
	const url = `accounting-firms/${params.accounting_firm_id}/catalog-offers/${params.catalog_offer_id}`
	return { promise: axios.delete(url) }
}

/**
 * @api GET /api/accounting-firms/{accountingFirmId}/catalog-offers
 *
 * @param {object} data
 * @param {number} data.accounting_firm_id
 * @param {Array<number>} data.categories
 * @param {number} data.page
 * @param {number} data.po
 * @param {string} data.text
 * @param {?object} cancelToken
 *
 * @return {{cancelToken: object, promise: Promise}}
 */
const listCatalogOffers = (data, cancelToken = null) => {
	if (cancelToken) {
		cancelToken.cancel('Request cancelled')
	}
	const newCancelToken = Configurator.CancelToken.source()

	const url = `accounting-firms/${data.accounting_firm_id}/catalog-offers`
	delete data.accounting_firm_id

	return {
		cancelToken: newCancelToken,
		promise: axios.get(url, {
			cancelToken: newCancelToken.token,
			params: data
		})
	}
}

/**
 * @api PATCH /api/accounting-firms/{accountingFirmId}/catalog-offers/{catalogOfferId}
 *
 * @param {number} accountingFirmId
 * @param {number} catalogOfferId
 * @param {object} data
 * @return {Promise<object>}
 */
const updateCatalogOffer = (accountingFirmId, catalogOfferId, data) => {
	const url = `accounting-firms/${accountingFirmId}/catalog-offers/${catalogOfferId}`
	return axios.patch(url, data)
}

/**
 * @api PATCH /api/offers/{accountingFirmId}/catalog-offers/{catalogOfferId}/catalog-services/{catalogServiceId}
 *
 * @param {number} accountingFirmId
 * @param {number} catalogOfferId
 * @param {number} catalogServiceId
 * @param {object} data
 * @return {Promise<object>}
 */
const updateCatalogServiceQuantity = (accountingFirmId, catalogOfferId, catalogServiceId, data) => {
	const url = `offers/${accountingFirmId}/catalog-offers/${catalogOfferId}/catalog-services/${catalogServiceId}`
	return axios.patch(url, data)
}

/**
 * @api POST /api/accounting-firms/{accountingFirmId}/categories
 *
 * @param {number} accountingFirmId
 * @param {object} data
 * @return {Promise<object>}
 */
const createCategory = (accountingFirmId, data) => {
	const url = `accounting-firms/${accountingFirmId}/categories`
	return axios.post(url, data)
}

/**
 * @api DELETE /api/accounting-firms/{accountingFirmId}/categories/{categoryId}
 *
 * @param {number} accountingFirmId
 * @param {number} categoryId
 * @return {Promise<object>}
 */
const deleteCategory = (accountingFirmId, categoryId) => {
	const url = `accounting-firms/${accountingFirmId}/categories/${categoryId}`
	return axios.delete(url)
}

/**
 * @api GET /api/accounting-firms/{accountingFirmId}/categories
 *
 * @param {number} accountingFirmId
 * @return {Promise<object>}
 */
const listCategories = accountingFirmId => {
	const url = `accounting-firms/${accountingFirmId}/categories`
	return axios.get(url)
}

/**
 * @api PATCH /api/accounting-firms/{accountingFirmId}/categories/{categoryId}
 *
 * @param {number} accountingFirmId
 * @param {number} categoryId
 * @param {object} data
 * @return {Promise<object>}
 */
const updateCategory = (accountingFirmId, categoryId, data) => {
	const url = `accounting-firms/${accountingFirmId}/categories/${categoryId}`
	return axios.patch(url, data)
}

/**
 *
 * @api GET /api/deliverables
 *
 * @return {Promise<object>}
 */
const listDeliverables = () => {
	const url = `deliverables`
	return axios.get(url)
}

/**
 * @api GET /api/durations
 *
 * @return {Promise<object>}
 */
const listDurations = () => {
	const url = `durations`
	return axios.get(url)
}

/**
 * @api GET /api/frequencies
 *
 * @return {Promise<object>}
 */
const listFrequenciesOptions = () => {
	const url = `frequencies`
	return axios.get(url)
}

/**
 * @api POST /api/accounting-firms/{accountingFirmId}/categories/{categoryId}/catalog-services
 *
 * @param {number} accountingFirmId
 * @param {number} categoryId
 * @param {object} catalogServiceData
 *
 * @return {Promise<object>}
 */
const createCatalogService = (accountingFirmId, categoryId, catalogServiceData) => {
	const url = `accounting-firms/${accountingFirmId}/categories/${categoryId}/catalog-services`
	return axios.post(url, catalogServiceData)
}

/**
 * @api DELETE /api/accounting-firms/{accountingFirmId}/catalog-services/{catalogServiceId}
 *
 * @param {number} accountingFirmId
 * @param {number} catalogServiceId
 * @return {Promise<object>}
 */
const deleteCatalogService = (accountingFirmId, catalogServiceId) => {
	const url = `accounting-firms/${accountingFirmId}/catalog-services/${catalogServiceId}`
	return axios.delete(url)
}

/**
 * @api GET /api/accounting-firms/{accountingFirmId}/catalog-offers/catalog-services
 *
 * @param {number} accountingFirmId
 * @return {Promise<object>}
 */
const listCatalogServices = accountingFirmId => {
	const url = `accounting-firms/${accountingFirmId}/catalog-services`
	return axios.get(url)
}

/**
 * @api GET /api/accounting-firms/{accountingFirmId}/categories/{categoryId}/catalog-services
 *
 * @param {number} accountingFirmId
 * @param {number} categoryId
 * @param {object} data
 * @param {object} cancelToken
 * @return {{cancelToken: object, promise: Promise}}
 */
const listCatalogServicesOfCategory = (accountingFirmId, categoryId, data, cancelToken) => {
	if (cancelToken) {
		cancelToken.cancel('Request cancelled')
	}
	const newCancelToken = Configurator.CancelToken.source()

	const url = `accounting-firms/${accountingFirmId}/categories/${categoryId}/catalog-services`
	return {
		cancelToken: newCancelToken,
		promise: axios.get(url, {
			cancelToken: newCancelToken.token,
			params: data
		})
	}
}

/**
 * @api PATCH /api/accounting-firms/{accountingFirmId}/categories/{categoryId}/catalog-services/{catalogServiceId}
 *
 * @param {number} accountingFirmId
 * @param {number} categoryId
 * @param {number} catalogServiceId
 * @param {object} catalogServiceData
 *
 * @return {Promise<object>}
 */
const updateCatalogService = (accountingFirmId, categoryId, catalogServiceId, catalogServiceData) => {
	const url = `accounting-firms/${accountingFirmId}/categories/${categoryId}/catalog-services/${catalogServiceId}`
	return axios.patch(url, catalogServiceData)
}

/**
 * @api GET /api/task-types
 *
 * @param {object} params
 * @param {boolean} params.for_task_models
 *
 * @return {{promise: Promise}}
 */
const listTaskTypes = params => {
	const url = `task-types`

	return {
		promise: axios.get(url, { params: params })
	}
}

export default {
	createCatalogOffer,
	deleteCatalogOffer,
	listCatalogOffers,
	updateCatalogOffer,
	updateCatalogServiceQuantity,

	createCategory,
	deleteCategory,
	listCategories,
	updateCategory,

	listDeliverables,

	listDurations,

	listFrequenciesOptions,

	createCatalogService,
	deleteCatalogService,
	listCatalogServices,
	listCatalogServicesOfCategory,
	updateCatalogService,

	listTaskTypes
}
